<template>
    <modal ref="modalRegistro" titulo="Registro anulado" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0">
                    <img :src="model.responsable.foto" width="46" height="46" class="obj-cover rounded-circle" />
                    <div class="col text-general">
                        <p class="f-17 f-600">
                            Encargado:
                        </p>
                        <p>
                            {{ model.responsable.nombre }}
                        </p>
                        <p class="f-15 mt-2 text-general">
                            Fecha y hora registro
                        </p>
                        <p class="f-15 mt-2 text-general">
                            Comentario
                        </p>
                    </div>
                    <div class="col">
                        <p class="text-general f-17 f-600">
                            {{ separadorNumero(model.valor) }}
                        </p>
                        <div class="row mx-0" style="height:24px;">
                            <div v-if="model.anulado_justificacion != null" class="bg-pink text-white f-14 br-20 px-2">
                                Anulado
                            </div>
                        </div>
                        <p class="mt-2 f-15 text-general">
                            {{ formatearFecha(model.created_at, 'D MMM Y - HH:mmA') }}
                        </p>
                        <p class="f-15 mt-2 text-general">
                            {{ model.comentario }}
                        </p>
                        <div v-if="model.anulado_justificacion == null && (fecha == hoy || fecha == ayer) && $can('boton_flujo_dinero_cajas_anular_tesoreria')" class="border br-8 text-general d-middle-center mt-2 mx-4 cr-pointer" style="height:32px;" @click="anular">
                            Anular registro
                        </div>
                    </div>
                </div>
                <div v-if="model.anulado_justificacion != null" class="row mx-0 mt-5">
                    <img :src="model.anulador.foto" width="46" height="46" class="obj-cover rounded-circle" />
                    <div class="col">
                        <p class="f-17 f-600 text-general">
                            Anulador por:
                        </p>
                        <p class="f-17 text-general">
                            {{ model.anulador.nombre }}
                        </p>
                        <p class="mt-2 text-general f-15">
                            {{ formatearFecha(model.updated_at, 'D MMM Y - HH:mmA') }}
                        </p>
                    </div>
                    <div class="col">
                        <p class="text-general f-15">
                            {{ model.anulado_justificacion }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            model: {
                anulado_justificacion: null,
                anulador: {
                    foto: '',
                    nombre: '',
                },
                comentario: null,
                created_at: null,
                created_by: null,
                estado: null,
                fecha: null,
                id: null,
                id_caja: null,
                responsable: {
                    foto: '',
                    nombre: '',
                },
                updated_at: null,
                updated_by: null,
                valor: null,
            },
            hoy: moment().format('Y-MM-DD'),
            ayer: moment().subtract(1,'days').format('Y-MM-DD'),
        }
    },
    computed: {
        ...mapGetters({
            fecha:'flujoDinero/caja/fecha',
        }),
    },
    methods: {
        toggle(row){
            this.model = row
            this.$refs.modalRegistro.toggle();
        },
        anular(){
            this.$refs.modalRegistro.toggle();
            this.$emit('anular', this.model)
        }
    }
}
</script>
<style lang="css" scoped>
.bg-pink{
    background-color: #FF5A60;
}
</style>
